import generatePath from 'utils/generatePath';

export const createChannel = c => ({
  _id: c._id,
  admins:
    c.admins?.map(u => ({
      ...u,
      name: u.fullName,
    })) || [],
  avatar: generatePath(c.avatar),
  canWriteAsChannel: c.canWriteAsChannel,
  company_id: c.company_id || c.company?._id || c.company,
  complete: c.complete || false,
  description: c.description,
  employees_ids: c.users?.map(e => e._id) || c.employees_ids || [],
  isActive: c.isActive || true,
  isAdmin: c.isAdmin,
  isDirect: c.isDirect || false,
  isMember: c.isMember,
  isReadOnly: c.isReadOnly,
  lastMessage_id: c.lastMessage_id || c.lastMessage?._id,
  lastMessage: c.lastMessage,
  loadingMessages: c.loadingMessages || false,
  mandatoriesUnreadCount: c.mandatoriesUnreadCount || c.unreadMandatoriesCount || 0,
  memberCount: c.memberCount,
  messages_ids: c.messages?.map(m => m._id) || c.messages_ids || [],
  messagesTotalCount: c.messagesCount || c.messagesTotalCount || 0,
  messagesUnreadCount: c.unreadMessagesCount || c.messagesUnreadCount || 0,
  name: c.name,
  page: c.page || 1,
  updatedAt: c.updatedAt || c.lastMessage?.createdAt,
  users:
    c.users?.map(u => ({
      ...u,
      name: u.fullName,
    })) || [],
  loading: c.loading,
  loaded: c.loaded,
  groupRole: c.groupRole,
  groupTeams: c.groupTeams,
  handoffFrom: c.handoffFrom,
  pinnedMessage: c.pinnedMessage,
  pinned: c.pinned,
});
