/* eslint-disable max-classes-per-file,no-cond-assign,no-shadow,prefer-destructuring,no-restricted-syntax */

class Locale {
  constructor(str) {
    let match;
    if (!(match = str != null ? str.match(/[a-z]+/gi) : undefined)) {
      return;
    }

    const [language, country] = Array.from(match);

    this.code = str;
    this.language = language.toLowerCase();
    if (country) {
      this.country = country.toUpperCase();
    }

    const normalized = [this.language];
    if (this.country) {
      normalized.push(this.country);
    }
    this.normalized = normalized.join('_');
    this.score = 1;
  }
}

Locale.default = new Locale('en');

class Locales {
  constructor(locales, def) {
    this.length = 0;
    this._index = null;

    this.sort = Array.prototype.sort;
    this.push = Array.prototype.push;

    this.toJSON = () => [...Array.from(this)];

    if (def) {
      this.default = new Locale(def);
    }

    locales.forEach(l => this.push(new Locale(l)));
  }

  index() {
    if (!this._index) {
      this._index = {};
      for (let idx = 0; idx < this.length; idx += 1) {
        const locale = this[idx];
        this._index[locale.normalized] = idx;
      }
    }

    return this._index;
  }

  best(locales) {
    const setLocale = l => {
      // When don't return the default
      const r = l;
      r.defaulted = false;
      return r;
    };

    let locale = Locale.default;
    if (locales && locales.default) {
      locale = locales.default;
    }
    locale.defaulted = true;

    if (!locales) {
      if (this[0]) {
        locale = this[0];
        locale.defaulted = true;
      }
      return locale;
    }

    const index = locales.index();

    for (const item of Array.from(this)) {
      const normalizedIndex = index[item.normalized];
      const languageIndex = index[item.language];

      if (normalizedIndex != null) {
        return setLocale(locales[normalizedIndex]);
      }
      if (languageIndex != null) {
        return setLocale(locales[languageIndex]);
      }
      for (const l of Array.from(locales)) {
        if (l.language === item.language) {
          return setLocale(l);
        }
      }
    }

    return locale;
  }

  toString() {
    return String(this.toJSON());
  }
}

const browserLocales = () => {
  let langs;

  if (!navigator) {
    return [];
  }

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    langs = navigator.languages;
  } else if (navigator.userLanguage) {
    // IE only
    langs = [navigator.userLanguage];
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    langs = [navigator.language];
  }

  return langs;
};

export default () => new Locales(browserLocales()).best(new Locales(['en', 'ca', 'es'])).code;
