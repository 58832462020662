import qs from 'querystring';
import Http from 'services/Http';
import { encryptFile } from 'utils/e2eEncryption';
import parseFileName from 'utils/getFileExtension';

export const MEDIA_LIMIT = 25;

class Attachments {
  async request(id, type, page = 1, perPage = MEDIA_LIMIT, sortBy = 'createdAt', sort = 'desc') {
    const params = {
      page,
      perPage,
      sortBy,
      sort,
      type,
    };

    const { data } = await Http.get(`/v1/message/${id}/attachments?${qs.stringify(params)}`);

    return data;
  }

  async links(_id, page, perPage = MEDIA_LIMIT) {
    const { links, totalCount } = await this.request(_id, 'link', page, perPage);

    return {
      links: links.filter(l => !!l),
      totalCount,
    };
  }

  media(_id, page, perPage = MEDIA_LIMIT) {
    return this.request(_id, 'media', page, perPage);
  }

  docs(_id, page, perPage = MEDIA_LIMIT, field, sort, type = 'doc') {
    return this.request(_id, type, page, perPage, field, sort);
  }

  forms(_id, page, perPage = MEDIA_LIMIT) {
    return this.request(_id, 'form', page, perPage);
  }

  async upload(company, groupId, file, locale, onUploadProgress, getCancelFunc, fileName) {
    const { type: mimeType } = file;
    const name = fileName || file.name;
    const { ext } = parseFileName(name);
    const {
      data: { id, url },
    } = await Http.post('/v1/attachment/sign-url', { mimeType, ext, groupId });
    await Http.putS3(url, file, {
      headers: {
        'x-amz-tagging': `company=${company}`,
        'Content-Type': mimeType,
        'Content-Length': file.size,
        'Cache-Control': 'max-age=1209600',
      },
      timeout: 0,
      onUploadProgress,
      getCancelFunc,
    });
    const response = await Http.patch('/v1/attachment/sign-url/uploaded', { id, name, groupId });
    return response.data;
  }

  async uploadWb(company, file, onUploadProgress, getCancelFunc, fileName, slug, aesKey) {
    const { type: mimeType } = file;
    const name = fileName || file.name;
    const { ext } = parseFileName(name);
    const groupId = 'whistleblower';
    const {
      data: { id, url },
    } = await Http.post(
      slug ? `/v1/wb/portal/${slug}/attachment/sign-url` : '/v1/wb/attachment/sign-url',
      {
        mimeType,
        ext,
        groupId,
      },
    );
    const postFile = await encryptFile(file, aesKey);
    await Http.putS3(url, postFile, {
      headers: {
        'x-amz-tagging': `company=${company}`,
        'Content-Type': mimeType,
        'Content-Length': postFile.size,
        'Cache-Control': 'max-age=1209600',
      },
      timeout: 0,
      onUploadProgress,
      getCancelFunc,
    });
    const response = await Http.patch(
      slug
        ? `/v1/wb/portal/${slug}/attachment/sign-url/uploaded`
        : '/v1/wb/attachment/sign-url/uploaded',
      {
        id,
        name,
        groupId,
        encrypted: true,
      },
    );
    return response.data;
  }
}

export default new Attachments();
