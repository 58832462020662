const splitRe = /^(.*?)(?:\.([^.]+?)|)$/;

const parseFileName = fileName => {
  if (typeof fileName !== 'string') {
    throw new TypeError(`Parameter 'fileName' must be a string, not ${typeof fileName}`);
  }
  const [, name, ext] = splitRe.exec(fileName);
  return { name, ext };
};

export default parseFileName;
