const removeEqualProperties = (list, property) => {
  let oldValue = 'start';
  return list
    .filter(e => !!e)
    .map(item => ({
      ...item,
      [property]: (() => {
        let value = item[property];
        if (typeof value === 'string') {
          value = value.toLowerCase();
        }
        if (oldValue === 'start') {
          oldValue = value;
          return value;
        }
        if (oldValue !== value) {
          oldValue = value;
          return value;
        }

        return null;
      })(),
    }));
};
export default removeEqualProperties;
