import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BACKEND_URL } from 'services/constants';
import Cookie from 'services/Cookie';

const prepareHeaders = (headers, { getState }) => {
  // By default, if we have a token in the store, let's use that for authenticated requests
  const { token } = getState().auth;
  const company = Cookie.get('company');
  const impersonate = Cookie.get('impersonate');
  if (token?.hash) {
    headers.set('authorization', `Bearer ${token.hash}`);
  }
  if (company) {
    headers.set('x-company', company._id);
  }
  if (impersonate) {
    headers.set('x-impersonate', impersonate);
  }
  return headers;
};
const baseQuery = fetchBaseQuery({
  baseUrl: `${BACKEND_URL}/api`,
  prepareHeaders,
});
export default baseQuery;
