const toRequest = obj =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => {
        return typeof value !== 'undefined';
      })
      .map(([key, value]) => [key, typeof value === 'boolean' ? (value && 1) || 0 : value]),
  );

export default toRequest;
