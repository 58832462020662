import firebase from 'firebase/app';
import 'firebase/analytics';

import { FIREBASE_ANALYTICS_MEASUREMENT } from 'services/constants';

const firebaseConfig = {
  apiKey: 'AIzaSyCQzVJgUxlpP0_gdfn7QKbMpaNO69ZU0J8',
  authDomain: 'rely-bb5a7.firebaseapp.com',
  databaseURL: 'https://rely-bb5a7.firebaseio.com',
  projectId: 'rely-bb5a7',
  storageBucket: 'rely-bb5a7.appspot.com',
  messagingSenderId: '19162348773',
  appId: '1:19162348773:web:9a180b09ce3096061d280c',
  measurementId: FIREBASE_ANALYTICS_MEASUREMENT,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();

const reduxBeaconFirebaseTarget = events => {
  events.forEach(event => {
    if (event.userId) {
      analytics.setUserId(event.userId);
    }
    if (event.type === 'page') {
      analytics.setCurrentScreen(event.path);
      analytics.logEvent('screen_view_event', {
        companyId: event.dimension1,
        channelId: event.dimension2,
      });
    } else if (event.type === 'event') {
      analytics.logEvent(event.action, {
        companyId: event.dimension1,
        channelId: event.dimension2,
        messageType: event.dimension3,
      });
    }
  });
};

export default reduxBeaconFirebaseTarget;
