/**
 * @flow
 */
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import fetchAndDecrypt from 'utils/fetchAndDecrypt';

const AudioAttachment = ({ attachment, aesKey }: { attachment: Object; aesKey: string }) => {
  const [decrypted, setDecrypted] = useState(null);
  useEffect(() => {
    fetchAndDecrypt(attachment, aesKey).then(newDecrypted => {
      setDecrypted(URL.createObjectURL(newDecrypted));
    });
  }, [attachment]);
  if (!decrypted) {
    return null;
  }
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <Box component="audio" controls sx={{ height: 30 }}>
      <source src={decrypted} type={attachment.mimeType} />
    </Box>
  );
};

export default AudioAttachment;
