import { createSelector } from 'reselect';
import populateChannels from 'store/selectors/populateChannels';

const currentChannel = (channels, currentId) => {
  if (currentId && channels[currentId]) return channels[currentId];

  const previousId = localStorage.getItem('currentId');

  if (channels[previousId]) return channels[previousId];

  const firstId = Object.keys(channels)[0];

  return channels[firstId];
};

export default createSelector(
  state => populateChannels(state),
  state => state.elements.ChannelsPanel.currentId,
  currentChannel,
);
