import { createSelector } from 'reselect';

const authCompany = (companies, auth) => {
  return companies[auth.company_id];
};

export default createSelector(
  state => state.companies,
  state => state.auth,
  authCompany,
);
