import { createSelector } from 'reselect';
import authUser from 'store/selectors/authUser';
import arrayToObject from 'utils/arrayToObject';
import dateDaily from 'utils/dateDaily';

const populateMessages = (messages, employees, channels, users, user) => {
  if (!user) {
    return {};
  }
  const messagesArray = Object.values(messages).map(m => ({
    ...m,
    isMine: m.user_id === user._id,
    replyTo: m.replyTo ? { ...m.replyTo, isMine: m.replyTo.from === user._id } : null,
    dateTime: dateDaily(m.createdAt),
    employee:
      (channels?.[m.channel_id]?.users
        ? channels[m.channel_id]?.users.find(u => u._id === m.employee_id)
        : null) ||
      employees[m.employee_id] ||
      m.from,
    user: users[m.user_id],
    seen: m.isRead,
  }));

  return arrayToObject(messagesArray);
};

export default createSelector(
  state => state.messages,
  state => state.employees.employees,
  state => state.channels.channels,
  state => state.users,
  state => authUser(state),
  populateMessages,
);
