import { decryptFile } from 'utils/e2eEncryption';

const fetchAndDecrypt = (attachment, aesKey) =>
  fetch(attachment.httpLink)
    .then(res => res.text())
    .then(async text => {
      const decrypted = await decryptFile(text, aesKey);
      return new Blob([decrypted], {
        type: 'application/download',
      });
    });

export default fetchAndDecrypt;
