import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import getSubdomain from 'utils/getSubdomain';

const originalTranslate = I18n.t.bind(I18n);

const replace = {
  'saint-gobain': 'Connecti@',
}[getSubdomain()];

I18n.t = (key, options) => {
  const translation = originalTranslate(key, options);
  return replace ? translation.replace(/Ommnio/g, replace) : translation;
};

const Translate = ({ value, dangerousHTML, tag: Tag = 'span', ...otherProps }) => {
  const translation = I18n.t(value, otherProps);

  if (dangerousHTML) {
    return <Tag dangerouslySetInnerHTML={{ __html: translation }} {...otherProps} />;
  }
  return <Tag {...otherProps}>{translation}</Tag>;
};

Translate.propTypes = {
  value: PropTypes.string.isRequired,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  dangerousHTML: PropTypes.bool,
};

Translate.defaultProps = {
  tag: 'span',
  dangerousHTML: false,
};

export { I18n, Translate };
