const categories = {
  ar: {
    ABUSE_OF_POWER: 'إساءة إستخدام السلطة',
    AUDITING_ACT: 'يتصرف المدققون',
    BRIBERY: 'الرشوة',
    CONFLICTS_OF_INTERESTS: 'تضارب المصالح',
    CORRUPTION: 'فساد',
    DISCRIMINATION: 'تمييز',
    FRAUD: 'احتيال',
    HR_RELATED_MATTERS: 'المسائل المتعلقة بالموارد البشرية',
    MONEY_LAUNDERING_ACT: 'غسيل أموال',
    OTHER: 'آخر',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'حماية الخصوصية والمعلومات الشخصية',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'حقوق الأفراد وحمايتهم',
    SEXISM: 'التمييز على أساس الجنس',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'الاستغلال الجنسي أو الإساءة أو التحرش',
    TERRORISM: 'الإرهاب',
    UNACCEPTABLE_BEHAVIOUR: 'السلوك غير المقبول',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'انتهاك سياسات الشركة',
  },
  bg: {
    ABUSE_OF_POWER: 'Злоупотреба с власт',
    AUDITING_ACT: 'Одиторски акт',
    BRIBERY: 'Подкуп',
    CONFLICTS_OF_INTERESTS: 'Конфликт на интереси',
    CORRUPTION: 'Корупция',
    DISCRIMINATION: 'Дискриминация',
    FRAUD: 'Измама',
    HR_RELATED_MATTERS: 'Въпроси, свързани с човешките ресурси',
    MONEY_LAUNDERING_ACT: 'Пране на пари',
    OTHER: 'Друго',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Защита на поверителността и личната информация',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Права и защита на физическите лица',
    SEXISM: 'Сексизъм',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Сексуална експлоатация, малтретиране или тормоз',
    TERRORISM: 'Тероризъм',
    UNACCEPTABLE_BEHAVIOUR: 'Неприемливо поведение',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Нарушаване на фирмената политика',
  },
  cs: {
    ABUSE_OF_POWER: 'Zneužití moci',
    AUDITING_ACT: 'Zákon o auditorech',
    BRIBERY: 'Úplatkářství',
    CONFLICTS_OF_INTERESTS: 'Střet zájmů',
    CORRUPTION: 'Korupce',
    DISCRIMINATION: 'Diskriminace',
    FRAUD: 'Podvod',
    HR_RELATED_MATTERS: 'záležitosti související s HR',
    MONEY_LAUNDERING_ACT: 'Praní špinavých peněz',
    OTHER: 'Jiné',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Ochrana soukromí a osobních údajů',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Práva a ochrana jednotlivců',
    SEXISM: 'Diskriminace podle pohlaví',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Sexuální vykořisťování, zneužívání nebo obtěžování',
    TERRORISM: 'Terorismus',
    UNACCEPTABLE_BEHAVIOUR: 'Nepřijatelné chování',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Porušení firemních zásad',
  },
  da: {
    ABUSE_OF_POWER: 'Magtmisbrug',
    AUDITING_ACT: 'Revisor loven',
    BRIBERY: 'Bestikkelse',
    CONFLICTS_OF_INTERESTS: 'Interessekonflikt',
    CORRUPTION: 'Korruption',
    DISCRIMINATION: 'Diskrimination',
    FRAUD: 'Svig',
    HR_RELATED_MATTERS: 'HR relaterede forhold',
    MONEY_LAUNDERING_ACT: 'Hvidvaskning',
    OTHER: 'Andet',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Beskyttelse af privatlivets fred og personoplysninger',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Rettigheder og beskyttelse af enkeltpersoner',
    SEXISM: 'Sexisme',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Seksuel udnyttelse, misbrug eller chikane',
    TERRORISM: 'Terrorisme',
    UNACCEPTABLE_BEHAVIOUR: 'Uacceptabel adfærd',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Overtrædelse af virksomhedens politikker',
  },
  de: {
    ABUSE_OF_POWER: 'Machtmissbrauch',
    AUDITING_ACT: 'Wirtschaftsprüfungsrecht',
    BRIBERY: 'Bestechung',
    CONFLICTS_OF_INTERESTS: 'Interessenskonflikt',
    CORRUPTION: 'Korruption',
    DISCRIMINATION: 'Diskriminierung',
    FRAUD: 'Betrug',
    HR_RELATED_MATTERS: 'Personalangelegenheiten',
    MONEY_LAUNDERING_ACT: 'Geldwäsche',
    OTHER: 'Sonstige',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Schutz der Privatsphäre und personenbezogener Daten',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Rechte und Schutz von Einzelpersonen',
    SEXISM: 'Sexismus',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Sexuelle Ausbeutung, Missbrauch oder Belästigung',
    TERRORISM: 'Terrorismus',
    UNACCEPTABLE_BEHAVIOUR: 'Inakzeptables Verhalten',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Verstoß gegen Unternehmensrichtlinien',
  },
  el: {
    ABUSE_OF_POWER: 'Κατάχρηση εξουσίας',
    AUDITING_ACT: 'Νόμος περί ελεγκτών',
    BRIBERY: 'Δωροδοκία',
    CONFLICTS_OF_INTERESTS: 'Σύγκρουση συμφερόντων',
    CORRUPTION: 'Διαφθορά',
    DISCRIMINATION: 'Διάκριση',
    FRAUD: 'Απάτη',
    HR_RELATED_MATTERS: 'Θέματα που σχετίζονται με το HR',
    MONEY_LAUNDERING_ACT: 'Νομιμοποίηση εσόδων από παράνομες δραστηριότητες',
    OTHER: 'Άλλο',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Προστασία του απορρήτου και των προσωπικών πληροφοριών',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Δικαιώματα και προστασία των φυσικών προσώπων',
    SEXISM: 'Σεξισμός',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Σεξουαλική εκμετάλλευση, κακοποίηση ή παρενόχληση',
    TERRORISM: 'Τρομοκρατία',
    UNACCEPTABLE_BEHAVIOUR: 'Απαράδεκτη Συμπεριφορά',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Παραβίαση των πολιτικών της εταιρείας',
  },
  en: {
    ABUSE_OF_POWER: 'Abuse of power',
    AUDITING_ACT: 'Auditors act',
    BRIBERY: 'Bribery',
    CONFLICTS_OF_INTERESTS: 'Conflict of interest',
    CORRUPTION: 'Corruption',
    DISCRIMINATION: 'Discrimination',
    FRAUD: 'Fraud',
    HR_RELATED_MATTERS: 'HR related matters',
    MONEY_LAUNDERING_ACT: 'Money laundering',
    OTHER: 'Other',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Protection of privacy and personal information',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Rights and protection of individuals',
    SEXISM: 'Sexism',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Sexual exploitation, abuse or harassment',
    TERRORISM: 'Terrorism',
    UNACCEPTABLE_BEHAVIOUR: 'Unacceptable Behavior',
    UNUSED: 'Bullying',
    VIOLATION_OF_COMPANY_POLICIES: 'Violation of company policies',
  },
  es: {
    ABUSE_OF_POWER: 'Abuso de poder',
    AUDITING_ACT: 'Ley de auditoría',
    BRIBERY: 'Soborno',
    CONFLICTS_OF_INTERESTS: 'Conflicto de intereses',
    CORRUPTION: 'Corrupción',
    DISCRIMINATION: 'Discriminación',
    FRAUD: 'Fraude',
    HR_RELATED_MATTERS: 'Asuntos relacionados con los recursos humanos',
    MONEY_LAUNDERING_ACT: 'Lavado de dinero',
    OTHER: 'Otro',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Protección de la privacidad y la información personal',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Derechos y protección de las personas',
    SEXISM: 'Sexismo',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Explotación, abuso o acoso sexual',
    TERRORISM: 'Terrorismo',
    UNACCEPTABLE_BEHAVIOUR: 'Comportamiento inaceptable',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Violación de las políticas de la empresa',
  },
  et: {
    ABUSE_OF_POWER: 'Võimu kuritarvitamine',
    AUDITING_ACT: 'Audiitorite tegevus',
    BRIBERY: 'Altkäemaks',
    CONFLICTS_OF_INTERESTS: 'Huvide konflikt',
    CORRUPTION: 'Korruptsioon',
    DISCRIMINATION: 'Diskrimineerimine',
    FRAUD: 'Pettus',
    HR_RELATED_MATTERS: 'Personaliga seotud küsimused',
    MONEY_LAUNDERING_ACT: 'Rahapesu',
    OTHER: 'Muu',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Eraelu puutumatuse ja isikuteabe kaitse',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Üksikisikute õigused ja kaitse',
    SEXISM: 'Seksism',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Seksuaalne ärakasutamine, kuritarvitamine või ahistamine',
    TERRORISM: 'Terrorism',
    UNACCEPTABLE_BEHAVIOUR: 'Vastuvõetamatu käitumine',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Ettevõtte poliitika rikkumine',
  },
  fi: {
    ABUSE_OF_POWER: 'Vallan väärinkäyttö',
    AUDITING_ACT: 'Tilintarkastajan toiminta',
    BRIBERY: 'Lahjonta',
    CONFLICTS_OF_INTERESTS: 'Eturistiriidat',
    CORRUPTION: 'Korruptio',
    DISCRIMINATION: 'Syrjintä',
    FRAUD: 'Petos',
    HR_RELATED_MATTERS: 'HR-asioita',
    MONEY_LAUNDERING_ACT: 'Rahanpesu',
    OTHER: 'Muu',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Yksityisyyden ja henkilötietojen suoja',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Yksilöiden oikeudet ja suojelu',
    SEXISM: 'Seksismi',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Seksuaalinen riisto, hyväksikäyttö tai häirintä',
    TERRORISM: 'Terrorismi',
    UNACCEPTABLE_BEHAVIOUR: 'Sopimaton käyttäytyminen',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Yrityksen käytäntöjen rikkominen',
  },
  fr: {
    ABUSE_OF_POWER: 'Abus de pouvoir',
    AUDITING_ACT: 'Fraude comptable',
    BRIBERY: "Trafic d'influence",
    CONFLICTS_OF_INTERESTS: "Conflit d'intérêt",
    CORRUPTION: 'Corruption',
    DISCRIMINATION: 'Discrimination',
    FRAUD: 'Fraude',
    HR_RELATED_MATTERS: 'Questions liées aux RH',
    MONEY_LAUNDERING_ACT: "Blanchiment d'argent",
    OTHER: 'Autre',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Protection de la vie privée et des renseignements personnels',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Droits et protection des personnes',
    SEXISM: 'Sexisme',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Exploitation sexuelle, abus ou harcèlement',
    TERRORISM: 'Terrorisme',
    UNACCEPTABLE_BEHAVIOUR: 'Comportement inacceptable',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: "Violation des politiques de l'entreprise",
  },
  hr: {
    ABUSE_OF_POWER: 'Zlouporaba moći',
    AUDITING_ACT: 'Postupanje revizora',
    BRIBERY: 'Mito',
    CONFLICTS_OF_INTERESTS: 'Sukob interesa',
    CORRUPTION: 'Korupcija',
    DISCRIMINATION: 'Diskriminacija',
    FRAUD: 'Prevare',
    HR_RELATED_MATTERS: 'Pitanja vezana za HR',
    MONEY_LAUNDERING_ACT: 'Pranje novca',
    OTHER: 'Drugo',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Zaštita privatnosti i osobnih podataka',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Prava i zaštita pojedinaca',
    SEXISM: 'Seksizam',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Seksualno iskorištavanje, zlostavljanje ili uznemiravanje',
    TERRORISM: 'Terorizam',
    UNACCEPTABLE_BEHAVIOUR: 'Neprihvatljivo ponašanje',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Kršenje pravila tvrtke',
  },
  hu: {
    ABUSE_OF_POWER: 'Hatalommal való visszaélés',
    AUDITING_ACT: 'Könyvvizsgálókról szóló törvény',
    BRIBERY: 'Megvesztegetés',
    CONFLICTS_OF_INTERESTS: 'Érdekütközés',
    CORRUPTION: 'Korrupció',
    DISCRIMINATION: 'Hátrányos megkülönböztetés',
    FRAUD: 'Csalás',
    HR_RELATED_MATTERS: 'HR kapcsolatos ügyek',
    MONEY_LAUNDERING_ACT: 'Pénzmosás',
    OTHER: 'Egyéb',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'A magánélet és a személyes adatok védelme',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Személyiségi jogok és személyek védelme',
    SEXISM: 'Szexizmus',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Szexuális kizsákmányolás, visszaélés vagy zaklatás',
    TERRORISM: 'Terrorizmus',
    UNACCEPTABLE_BEHAVIOUR: 'Elfogadhatatlan viselkedés',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'A vállalati szabályzat megsértése',
  },
  is: {
    ABUSE_OF_POWER: 'Misbeiting valds',
    AUDITING_ACT: 'Endurskoðendur starfa',
    BRIBERY: 'Mútuþægni',
    CONFLICTS_OF_INTERESTS: 'Hagsmunaárekstur',
    CORRUPTION: 'Spilling',
    DISCRIMINATION: 'Mismunun',
    FRAUD: 'Svik',
    HR_RELATED_MATTERS: 'HR tengd mál',
    MONEY_LAUNDERING_ACT: 'Peningaþvætti',
    OTHER: 'Annað',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Vernd friðhelgi einkalífs og persónuupplýsinga',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Réttindi og vernd einstaklinga',
    SEXISM: 'Kynlífshyggja',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Kynferðisleg misnotkun, misnotkun eða áreitni',
    TERRORISM: 'Hryðjuverk',
    UNACCEPTABLE_BEHAVIOUR: 'Óásættanleg hegðun',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Brot á stefnu fyrirtækisins',
  },
  it: {
    ABUSE_OF_POWER: 'Abuso di potere',
    AUDITING_ACT: 'Legge sui revisori dei conti',
    BRIBERY: 'Corruzione',
    CONFLICTS_OF_INTERESTS: 'Conflitto di interessi',
    CORRUPTION: "Abuso d'ufficio",
    DISCRIMINATION: 'Discriminazione',
    FRAUD: 'Frode',
    HR_RELATED_MATTERS: 'Questioni relative alle risorse umane',
    MONEY_LAUNDERING_ACT: 'Riciclaggio',
    OTHER: 'Altro',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Tutela della privacy e delle informazioni personali',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Diritti e tutele individuali',
    SEXISM: 'Sessismo',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Sfruttamento, abuso o molestie sessuali',
    TERRORISM: 'Terrorismo',
    UNACCEPTABLE_BEHAVIOUR: 'Comportamento inaccettabile',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Violazione delle politiche aziendali',
  },
  ja: {
    ABUSE_OF_POWER: '権力の乱用',
    AUDITING_ACT: '監査人は行動します',
    BRIBERY: '贈収賄',
    CONFLICTS_OF_INTERESTS: '利益相反',
    CORRUPTION: '腐敗',
    DISCRIMINATION: '差別',
    FRAUD: '詐欺',
    HR_RELATED_MATTERS: '人材関連事項',
    MONEY_LAUNDERING_ACT: '資金洗浄',
    OTHER: '他の',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'プライバシーと個人情報の保護',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: '個人の権利と保護',
    SEXISM: '性差別',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: '性的搾取、虐待または嫌がらせ',
    TERRORISM: 'テロ',
    UNACCEPTABLE_BEHAVIOUR: '容認できない行動',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: '会社の方針への違反',
  },
  lt: {
    ABUSE_OF_POWER: 'Piktnaudžiavimas valdžia',
    AUDITING_ACT: 'Auditorių aktas',
    BRIBERY: 'Kyšininkavimas',
    CONFLICTS_OF_INTERESTS: 'Interesų konfliktas',
    CORRUPTION: 'Korupcija',
    DISCRIMINATION: 'Diskriminacija',
    FRAUD: 'Sukčiavimas',
    HR_RELATED_MATTERS: 'HR klausimai',
    MONEY_LAUNDERING_ACT: 'Pinigų plovimas',
    OTHER: 'Kita',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Privatumo ir asmeninės informacijos apsauga',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Asmenų teisės ir apsauga',
    SEXISM: 'Seksizmas',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Seksualinis išnaudojimas, prievarta arba priekabiavimas',
    TERRORISM: 'Terorizmas',
    UNACCEPTABLE_BEHAVIOUR: 'Nepriimtinas elgesys',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Įmonės politikos pažeidimas',
  },
  lv: {
    ABUSE_OF_POWER: 'Varas ļaunprātīga izmantošana',
    AUDITING_ACT: 'Revidentu likums',
    BRIBERY: 'Kukuļošana',
    CONFLICTS_OF_INTERESTS: 'Interešu konflikts',
    CORRUPTION: 'Korupcija',
    DISCRIMINATION: 'Diskriminācija',
    FRAUD: 'Krāpšana',
    HR_RELATED_MATTERS: 'HR saistīti jautājumi',
    MONEY_LAUNDERING_ACT: 'Naudas atmazgāšana',
    OTHER: 'Cits',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Privātuma un personiskās informācijas aizsardzība',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Personu tiesības un aizsardzība',
    SEXISM: 'Seksisms',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Seksuāla izmantošana, vardarbība vai uzmākšanās',
    TERRORISM: 'Terorisms',
    UNACCEPTABLE_BEHAVIOUR: 'Nepieņemama uzvedība',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Uzņēmuma politiku pārkāpšana',
  },
  mk_MK: {
    ABUSE_OF_POWER: 'Злоупотреба на моќта',
    AUDITING_ACT: 'Закон за ревизори',
    BRIBERY: 'Мито',
    CONFLICTS_OF_INTERESTS: 'Конфликт на интереси',
    CORRUPTION: 'Корупција',
    DISCRIMINATION: 'Дискриминација',
    FRAUD: 'Измама',
    HR_RELATED_MATTERS: 'Работи поврзани со човечки ресурси',
    MONEY_LAUNDERING_ACT: 'Перење пари',
    OTHER: 'Друго',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Заштита на приватноста и личните информации',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Права и заштита на поединци',
    SEXISM: 'Сексизам',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Сексуална експлоатација, злоупотреба или вознемирување',
    TERRORISM: 'Тероризам',
    UNACCEPTABLE_BEHAVIOUR: 'Неприфатливо однесување',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Прекршување на политиките на компанијата',
  },
  nl: {
    ABUSE_OF_POWER: 'Machtsmisbruik',
    AUDITING_ACT: 'Wet betreffende auditors',
    BRIBERY: 'Omkoping',
    CONFLICTS_OF_INTERESTS: 'Belangenconflict',
    CORRUPTION: 'Corruptie',
    DISCRIMINATION: 'Discriminatie',
    FRAUD: 'Fraude',
    HR_RELATED_MATTERS: 'HR gerelateerde zaken',
    MONEY_LAUNDERING_ACT: 'Witwassen van geld',
    OTHER: 'Overige',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Bescherming van privacy en persoonlijke informatie',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Rechten en bescherming van personen',
    SEXISM: 'Seksisme',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Seksuele uitbuiting, misbruik of intimidatie',
    TERRORISM: 'Terrorisme',
    UNACCEPTABLE_BEHAVIOUR: 'Onaanvaardbaar gedrag',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Schending van het bedrijfsbeleid',
  },
  no: {
    ABUSE_OF_POWER: 'Maktmisbruk',
    AUDITING_ACT: 'Revisjonsforordningen',
    BRIBERY: 'Bestikkelser',
    CONFLICTS_OF_INTERESTS: 'Interessekonflikt',
    CORRUPTION: 'Korrupsjon',
    DISCRIMINATION: 'Diskriminering',
    FRAUD: 'Bedrageri',
    HR_RELATED_MATTERS: 'HR relaterte saker',
    MONEY_LAUNDERING_ACT: 'Hvitvasking',
    OTHER: 'Annet',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Beskyttelse av personvern og personopplysninger',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Rettigheter og beskyttelse av enkeltpersoner',
    SEXISM: 'Sexisme',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Seksuell utnyttelse, overgrep eller trakassering',
    TERRORISM: 'Terrorisme',
    UNACCEPTABLE_BEHAVIOUR: 'Uakseptabel oppførsel',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Brudd på selskapets retningslinjer',
  },
  pl: {
    ABUSE_OF_POWER: 'Nadużycie władzy',
    AUDITING_ACT: 'Audytorzy działają',
    BRIBERY: 'Przekupstwo',
    CONFLICTS_OF_INTERESTS: 'Konflikt interesów',
    CORRUPTION: 'Korupcja',
    DISCRIMINATION: 'Dyskryminacja',
    FRAUD: 'Oszustwo',
    HR_RELATED_MATTERS: 'Sprawy kadrowe',
    MONEY_LAUNDERING_ACT: 'Pranie brudnych pieniędzy',
    OTHER: 'Inne',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Ochrona prywatności i danych osobowych',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Prawa i ochrona osób fizycznych',
    SEXISM: 'Seksizm',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Wykorzystywanie seksualne, wykorzystywanie lub nękanie',
    TERRORISM: 'Terroryzm',
    UNACCEPTABLE_BEHAVIOUR: 'Niedopuszczalne zachowanie',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Naruszenie zasad firmy',
  },
  pt: {
    ABUSE_OF_POWER: 'Abuso de poder',
    AUDITING_ACT: 'Ações de auditores',
    BRIBERY: 'Suborno',
    CONFLICTS_OF_INTERESTS: 'Conflito de interesses',
    CORRUPTION: 'Corrupção',
    DISCRIMINATION: 'Discriminação',
    FRAUD: 'Fraude',
    HR_RELATED_MATTERS: 'Assuntos relacionados com RH',
    MONEY_LAUNDERING_ACT: 'Branqueamento de capitais',
    OTHER: 'Outro',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Proteção de privacidade e informações pessoais',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Direitos e proteção dos indivíduos',
    SEXISM: 'Sexismo',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Exploração sexual, abuso ou assédio',
    TERRORISM: 'Terrorismo',
    UNACCEPTABLE_BEHAVIOUR: 'Comportamento Inaceitável',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Violação das políticas da empresa',
  },
  ro: {
    ABUSE_OF_POWER: 'Abuz de putere',
    AUDITING_ACT: 'Actul auditorilor',
    BRIBERY: 'Mită',
    CONFLICTS_OF_INTERESTS: 'Conflict de interese',
    CORRUPTION: 'Corupție',
    DISCRIMINATION: 'Discriminare',
    FRAUD: 'Fraudă',
    HR_RELATED_MATTERS: 'chestiuni legate de HR',
    MONEY_LAUNDERING_ACT: 'Spălarea banilor',
    OTHER: 'Altele',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Protecția vieții private și a informațiilor personale',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Drepturile și protecția persoanelor',
    SEXISM: 'Sexism',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Exploatare sexuală, abuz sau hărțuire',
    TERRORISM: 'Terorism',
    UNACCEPTABLE_BEHAVIOUR: 'Comportament inacceptabil',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Încălcarea politicilor companiei',
  },
  ru: {
    ABUSE_OF_POWER: 'Злоупотребление силой',
    AUDITING_ACT: 'Акт аудита',
    BRIBERY: 'Взяточничество',
    CONFLICTS_OF_INTERESTS: 'Конфликт интересов',
    CORRUPTION: 'Коррупция',
    DISCRIMINATION: 'Дискриминация',
    FRAUD: 'Мошенничество',
    HR_RELATED_MATTERS: 'Вопросы, связанные с кадрами',
    MONEY_LAUNDERING_ACT: 'Отмывание денег',
    OTHER: 'Другое',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Защита конфиденциальности и личной информации',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Права и защита личности',
    SEXISM: 'Сексизм',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Сексуальная эксплуатация, насилие или домогательства',
    TERRORISM: 'Терроризм',
    UNACCEPTABLE_BEHAVIOUR: 'Недопустимое поведение',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Нарушение политики компании',
  },
  sk: {
    ABUSE_OF_POWER: 'Zneužitie právomoci',
    AUDITING_ACT: 'Zákon o audítoroch',
    BRIBERY: 'Úplatkárstvo',
    CONFLICTS_OF_INTERESTS: 'Konflikt záujmov',
    CORRUPTION: 'Korupcia',
    DISCRIMINATION: 'Diskriminácia',
    FRAUD: 'Podvod',
    HR_RELATED_MATTERS: 'záležitosti súvisiace s HR',
    MONEY_LAUNDERING_ACT: 'Pranie špinavých peňazí',
    OTHER: 'Iné',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Ochrana súkromia a osobných údajov',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Práva a ochrana osôb',
    SEXISM: 'Sexizmus',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Sexuálne využívanie, zneužívanie alebo obťažovanie',
    TERRORISM: 'Terorizmus',
    UNACCEPTABLE_BEHAVIOUR: 'Neprípustné správanie',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Porušenie zásad spoločnosti',
  },
  sl: {
    ABUSE_OF_POWER: 'Zloraba pooblastil',
    AUDITING_ACT: 'Zakon o revizorjih',
    BRIBERY: 'Podkupovanje',
    CONFLICTS_OF_INTERESTS: 'Navzkrižje interesov',
    CORRUPTION: 'Korupcija',
    DISCRIMINATION: 'Diskriminacija',
    FRAUD: 'Goljufija',
    HR_RELATED_MATTERS: 'Zadeve, povezane s kadri',
    MONEY_LAUNDERING_ACT: 'Pranje denarja',
    OTHER: 'Drugo',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Varstvo zasebnosti in osebnih podatkov',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Pravice in varstvo posameznikov',
    SEXISM: 'Spolna diskriminacija',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Spolno izkoriščanje, zloraba ali nadlegovanje',
    TERRORISM: 'Terorizem',
    UNACCEPTABLE_BEHAVIOUR: 'Nesprejemljivo vedenje',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Kršitev politik podjetja',
  },
  sq: {
    ABUSE_OF_POWER: 'Abuzim me pushtetin',
    AUDITING_ACT: 'Akti i audituesve',
    BRIBERY: 'Ryshfet',
    CONFLICTS_OF_INTERESTS: 'Konflikt interesi',
    CORRUPTION: 'Korrupsion',
    DISCRIMINATION: 'Diskriminim',
    FRAUD: 'Mashtrim',
    HR_RELATED_MATTERS: 'Çështje të lidhura me burimet njerëzore',
    MONEY_LAUNDERING_ACT: 'Larje parash',
    OTHER: 'Tjetër',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Mbrojtje e privatësisë dhe informacionit personal',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Të drejtat dhe mbrojtja e individëve',
    SEXISM: 'Seksizëm',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Shfrytëzim, abuzim ose ngacmim seksual',
    TERRORISM: 'Terrorizëm',
    UNACCEPTABLE_BEHAVIOUR: 'Sjellje e papranueshme',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Shkelje e politikave të kompanisë',
  },
  sr: {
    ABUSE_OF_POWER: 'Zloupotreba ovlašćenja',
    AUDITING_ACT: 'Zakon o revizorima',
    BRIBERY: 'Podmićivanje',
    CONFLICTS_OF_INTERESTS: 'Sukob interesa',
    CORRUPTION: 'Korupcija',
    DISCRIMINATION: 'Diskriminacija',
    FRAUD: 'Prevara',
    HR_RELATED_MATTERS: 'Problemi u vezi sa ljudskim resursima',
    MONEY_LAUNDERING_ACT: 'Pranje novca',
    OTHER: 'Ostalo',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Zaštita privatnosti i podataka o ličnosti',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Prava i zaštita pojedinaca',
    SEXISM: 'Seksizam',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Seksualno iskorišćavanje, zloupotreba ili uznemiravanje',
    TERRORISM: 'Terorizam',
    UNACCEPTABLE_BEHAVIOUR: 'Neprihvatljivo ponašanje',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Kršenje politika kompanije',
  },
  sv: {
    ABUSE_OF_POWER: 'Maktmissbruk',
    AUDITING_ACT: 'Revisionslagen',
    BRIBERY: 'Mutbrott',
    CONFLICTS_OF_INTERESTS: 'Intressekonflikt',
    CORRUPTION: 'Korruption',
    DISCRIMINATION: 'Diskriminering',
    FRAUD: 'Bedrägeri',
    HR_RELATED_MATTERS: 'HR-relaterade frågor',
    MONEY_LAUNDERING_ACT: 'Penningtvätt',
    OTHER: 'Övrigt',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Skydd av integritet och personlig information',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Rättigheter och skydd för individer',
    SEXISM: 'Sexism',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Sexuellt utnyttjande, övergrepp eller trakasserier',
    TERRORISM: 'Terrorism',
    UNACCEPTABLE_BEHAVIOUR: 'Oacceptabelt beteende',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Brott mot företagets policyer',
  },
  tr: {
    ABUSE_OF_POWER: 'Yetkinin kötüye kullanılması',
    AUDITING_ACT: 'Denetçiler yasası',
    BRIBERY: 'Rüşvet',
    CONFLICTS_OF_INTERESTS: 'Çıkar çatışması',
    CORRUPTION: 'Yolsuzluk',
    DISCRIMINATION: 'Ayrımcılık',
    FRAUD: 'Dolandırıcılık',
    HR_RELATED_MATTERS: 'İK ile ilgili konular',
    MONEY_LAUNDERING_ACT: 'Kara para aklama',
    OTHER: 'Diğer',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Gizliliğin ve kişisel bilgilerin korunması',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Kişilerin hakları ve korunması',
    SEXISM: 'Cinsiyetçilik',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Cinsel sömürü, istismar veya taciz',
    TERRORISM: 'Terörizm',
    UNACCEPTABLE_BEHAVIOUR: 'Kabul Edilemez Davranışlar',
    VIOLATION_OF_COMPANY_POLICIES: 'Şirket politikalarının ihlali',
  },
  uk: {
    ABUSE_OF_POWER: 'Зловживання повноваженнями',
    AUDITING_ACT: 'Закон про аудит',
    BRIBERY: 'Підкуп',
    CONFLICTS_OF_INTERESTS: 'Конфлікт інтересів',
    CORRUPTION: 'Корупція',
    DISCRIMINATION: 'Дискримінація',
    FRAUD: 'Шахрайство',
    HR_RELATED_MATTERS: 'Питання, пов’язані з кадрами',
    MONEY_LAUNDERING_ACT: 'Легалізація грошових засобів незаконним шляхом',
    OTHER: 'Інше',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
      'Захист конфіденційності та особистої інформації',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Права та захист осіб',
    SEXISM: 'Сексизм',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT:
      'Сексуальна експлуатація, насильство або переслідування',
    TERRORISM: 'Тероризм',
    UNACCEPTABLE_BEHAVIOUR: 'Неприйнятна поведінка',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Порушення політики компанії',
  },
  vi: {
    ABUSE_OF_POWER: 'Lạm dụng quyền lực',
    AUDITING_ACT: 'Đạo luật về kiểm toán',
    BRIBERY: 'Hối lộ',
    CONFLICTS_OF_INTERESTS: 'Xung đột lợi ích',
    CORRUPTION: 'Tham nhũng',
    DISCRIMINATION: 'Phân biệt đối xử',
    FRAUD: 'Gian lận',
    HR_RELATED_MATTERS: 'Các vấn đề liên quan đến nhân sự',
    MONEY_LAUNDERING_ACT: 'Rửa tiền',
    OTHER: 'Khác',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: 'Bảo vệ quyền riêng tư và thông tin cá nhân',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Quyền và bảo vệ cá nhân',
    SEXISM: 'Phân biệt giới tính',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Bóc lột, lạm dụng hoặc quấy rối tình dục',
    TERRORISM: 'Khủng bố',
    UNACCEPTABLE_BEHAVIOUR: 'Hành vi không được chấp nhận',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: 'Vi phạm các chính sách của công ty',
  },
  zh_CN: {
    ABUSE_OF_POWER: '滥用权力',
    AUDITING_ACT: '审计法',
    BRIBERY: '贿赂',
    CONFLICTS_OF_INTERESTS: '利益冲突',
    CORRUPTION: '贪污',
    DISCRIMINATION: '歧视',
    FRAUD: '诈骗',
    HR_RELATED_MATTERS: '人力资源相关事宜',
    MONEY_LAUNDERING_ACT: '洗钱',
    OTHER: '其他',
    PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION: '保护隐私和个人信息',
    RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: '个人权利与保护',
    SEXISM: '性别歧视',
    SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: '性剥削、性虐待或性骚扰',
    TERRORISM: '恐怖主义',
    UNACCEPTABLE_BEHAVIOUR: '不可接受的行为',
    UNUSED: null,
    VIOLATION_OF_COMPANY_POLICIES: '违反公司政策',
  },
};

categories.ca = {
  ABUSE_OF_POWER: 'Abus de poder',
  AUDITING_ACT: "Llei d'auditoria",
  BRIBERY: 'Soborn',
  CONFLICTS_OF_INTERESTS: "Conflicte d'interesessos",
  CORRUPTION: 'Corrupció',
  DISCRIMINATION: 'Discriminació',
  FRAUD: 'Frau',
  HR_RELATED_MATTERS: 'Asumptes relacionats amb els recursos humans',
  MONEY_LAUNDERING_ACT: 'Blanqueig de diners',
  OTHER: 'Altres',
  PROTECTION_OF_PRIVACY_AND_PERSONAL_INFORMATION:
    'Protecció de la privacitat i la informació personal',
  RIGHTS_AND_PROTECTION_OF_INDIVIDUALS: 'Drets i protecció de les persones',
  SEXISM: 'Sexisme',
  SEXUAL_EXPLOITATION_OR_ABUSE_OR_HARASSMENT: 'Explotació, abus o assetjament sexual',
  TERRORISM: 'Terrorisme',
  UNACCEPTABLE_BEHAVIOUR: 'Comportamient inacceptable',
  UNUSED: null,
  VIOLATION_OF_COMPANY_POLICIES: "Violació de las polítiques de l'empresa",
};

export default categories;
