/* eslint-disable class-methods-use-this */
import Http from 'services/Http';
import to from 'util-to';

class UrlPreview {
  async generate(url) {
    const [err, response] = await to(Http.get(`/v1/preview?url=${encodeURIComponent(url)}`));
    if (err) return null;
    return response.data;
  }
}

export default new UrlPreview();
