import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';
import { createChannel } from 'store/app/entities/channels/createChannel';
import populateMessages from 'store/selectors/populateMessages';
import arrayToObject from 'utils/arrayToObject';
import groupAndSortMessages from 'utils/groupAndSortMessages';

const getPartnerId = (employeesIds, authEmployeeId) =>
  employeesIds.filter(_id => _id !== authEmployeeId)[0];

const populateChannels = (channels = {}, messages, authEmployee) => {
  const channelsArray = Object.values(channels).map(c => ({
    ...c,
    avatar: c.isDirect
      ? c.users[getPartnerId(c.employees_ids, authEmployee?._id)]?.avatar || c.avatar
      : c.avatar,
    messages: groupAndSortMessages(uniq(c.messages_ids)?.map(_id => messages[_id])),
    isMember: c.isDirect || c.isMember,
    employees: (c.users || []).map(e => ({
      ...e,
      company_id: e.company,
      user_id: typeof e.user === 'object' && e.user ? e.user._id : e.user,
      channels: e.channels ? e.channels.map(channel => createChannel(channel)) : [],
      name: e.firstName ? `${e.firstName} ${e.surName}` : '*****',
    })),
    memberCount: c.memberCount,
    groupTeams: c.groupTeams,
    messageId: null,
  }));

  return arrayToObject(channelsArray);
};

export default createSelector(
  state => state.channels.channels,
  populateMessages,
  state => state.auth.employee,
  populateChannels,
);
