import { isRejectedWithValue } from '@reduxjs/toolkit';
import auth from 'store/app/auth/action';

export const unauthenticatedMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(auth.logout());
    }
    return next(action);
  };
