/* eslint-disable class-methods-use-this,no-plusplus,no-console */
import Cookies from 'universal-cookie';
import removeCookies from 'utils/removeCookies';

class Cookie {
  constructor() {
    this.cookies = new Cookies();
  }

  set(name, value, overrideOptions = {}) {
    const options = {
      path: '/',
      domain: '.ommnio.com',
      secure: true,
      sameSite: 'Lax',
      ...overrideOptions,
    };

    try {
      this.cookies.set(name, typeof value === 'string' ? value : JSON.stringify(value), options);
    } catch (err) {
      this.cookies.set(name, value, options);
    }
  }

  get(name) {
    try {
      const response = JSON.parse(this.cookies.get(name));
      return response;
    } catch (err) {
      return this.cookies.get(name);
    }
  }

  clear() {
    const all = this.cookies.getAll();
    Object.keys(all).forEach(key => {
      this.cookies.set(key, '');
      this.cookies.remove(key);
    });

    removeCookies();
  }
}

export default new Cookie();
