import toWav from 'audiobuffer-to-wav';
import {
  getContext,
  OfflineContext,
  PitchShift,
  setContext,
  ToneAudioBuffer,
  ToneBufferSource,
} from 'tone';
import randomInt from 'utils/randomInt';

const distortAudio = async blob => {
  const pitch = Math.random() > 0.5 ? randomInt(-6, -3) : randomInt(3, 6);
  const buffer = await ToneAudioBuffer.fromUrl(URL.createObjectURL(blob));
  const offlineCtx = new OfflineContext(2, buffer.duration, getContext().sampleRate);
  const prevContext = getContext();
  setContext(offlineCtx);
  const source = new ToneBufferSource(buffer);
  const pitchShift = new PitchShift({ pitch }).toDestination();
  source.connect(pitchShift);
  await source.start(0);
  const destBuffer = await offlineCtx.render();
  setContext(prevContext);
  const arrayBuffer = toWav(destBuffer.get());
  return new Blob([arrayBuffer], { type: 'audio/wav' });
};

export default distortAudio;
