import isAbsoluteUrl from 'is-absolute-url';
import { BACKEND_URL } from 'services/constants';

const S3_BUCKET = '//s3-eu-west-1.amazonaws.com/ommnio-datastore/';
const ATTACHMENT_DOMAIN = '//a.ommnio.com/';

export default url => {
  if (!url) return url;

  if (isAbsoluteUrl(url)) {
    if (url.indexOf(S3_BUCKET) > -1) {
      return url.replace(S3_BUCKET, ATTACHMENT_DOMAIN);
    }
    return url;
  }

  return `${BACKEND_URL}${url}`;
};
