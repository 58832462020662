import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import moment from 'moment/moment';
import { setLocale } from 'react-redux-i18n';
import { persistReducer, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import Cookie from 'services/Cookie';
import { FLUSH_USER_DATA, LOGOUT_USER_PENDING } from 'store/app/auth/action';
import { SET_ARCHIVED_CHANNEL_FULFILLED } from 'store/app/entities/channels/action';
import { ADD_MESSAGE, SEND_MESSAGE_FULFILLED } from 'store/app/entities/messages/action';
import AddRehydratedTransform from 'utils/AddRehydratedTransform';
import redirect from 'utils/redirect';

const company = Cookie.get('company');

const initialState = {
  token: Cookie.get('token'),
  duration: null,
  expiresAt: null,
  company_id: company?._id,
  error: null,
  fulfilledTimeStamp: null,
  isError: false,
  isFetching: false,
  isLoading: false,
  isSuccess: false,
  isUninitialized: true,
  loading: false,
  startedTimeStamp: null,
  rehydrated: false,
};

const isNotInvited = user => isEmpty(user.companies);

const getCompany = companies =>
  companies.find(c => c._id === company?._id) || { employee: { _id: 'x' } };

const setTokenCookie = (keepSessionOpen, token) => {
  const options = keepSessionOpen && token.expiresAt ? { expires: new Date(token.expiresAt) } : {};
  Cookie.set('token', token, options);
  return options;
};

const ROLE_SUPER_ADMIN = 2;

const authReducer = (state = { ...initialState }, { type, key, payload, meta, asyncDispatch }) => {
  switch (type) {
    case 'GET_USER_DATA_PENDING':
      return {
        ...state,
        loading: !!state.isUninitialized,
        isLoading: !!state.isUninitialized,
        startedTimeStamp: Date.now(),
        isFetching: true,
      };

    case 'GET_USER_DATA_FULFILLED': {
      const { employee } = getCompany(payload.companies);
      return {
        ...state,
        loading: false,
        isLoading: false,
        user_id: payload._id,
        employee_id: employee._id,
        isSuperAdmin: payload.role === ROLE_SUPER_ADMIN,
        employee: {
          ...employee,
          name: [employee.firstName, employee.surName].filter(Boolean).join(' '),
          user: {
            ...(typeof employee.user === 'string' ? { _id: employee.user } : employee.user),
            ...payload,
            isSuperAdmin: payload.role === ROLE_SUPER_ADMIN,
          },
        },
        fulfilledTimeStamp: Date.now(),
        isFetching: false,
        isUninitialized: false,
        error: null,
        isError: false,
        isSuccess: true,
        rehydrated: false,
      };
    }

    case 'GET_USER_DATA_REJECTED':
      return {
        ...state,
        loading: false,
        isLoading: false,
        rejected: true,
        error: payload,
        fulfilledTimeStamp: null,
        isFetching: false,
        isError: true,
        isSuccess: false,
      };

    case 'LOGIN_USER_FULFILLED': {
      const { token, user } = payload;
      const options = setTokenCookie(meta.keepSessionOpen, token);

      const lastCompany =
        user.role === ROLE_SUPER_ADMIN
          ? user.companies.find(c => c._id === Cookie.get('company')?._id) || user.companies[0]
          : user.companies?.length && orderBy(user.companies, ['employee.lastSeen'], ['desc'])[0];

      const { name, subDomain, _id } = lastCompany || {
        name: 'x',
        subDomain: 'x',
        _id: 'x',
      };

      Cookie.set(
        'company',
        {
          name,
          subDomain,
          _id,
        },
        options,
      );

      if (meta.returnUrl) {
        window.location.replace(meta.returnUrl);
      } else if (isNotInvited(user)) {
        redirect('/auth/login-no-invitation');
      } else {
        Cookie.set(
          'company',
          {
            name,
            subDomain,
            _id,
            isAcceptTerms: lastCompany.isAcceptTerms,
          },
          options,
        );

        redirect('/home/messages', subDomain);
      }
      return { ...state, token };
    }

    case FLUSH_USER_DATA:
    case LOGOUT_USER_PENDING: {
      storage('ommnio').removeItem('persist:auth');
      return { ...initialState };
    }

    case 'TOKEN_REFRESHED': {
      const { token, keepSessionOpen } = payload;
      setTokenCookie(keepSessionOpen, token);
      return { ...state, token };
    }

    case 'VERIFY_REJECTED': {
      Cookie.set('token', '');
      return initialState;
    }

    case ADD_MESSAGE:
    case SEND_MESSAGE_FULFILLED: {
      let archivedChannels = state.employee?.archivedChannels || [];
      const wasArchived = archivedChannels.includes(payload.channel);
      if (wasArchived) {
        archivedChannels = archivedChannels.filter(c => c !== payload.channel);
        return { ...state, employee: { ...state.employee, archivedChannels } };
      }
      return state;
    }

    case SET_ARCHIVED_CHANNEL_FULFILLED: {
      let archivedChannels = state.employee?.archivedChannels || [];
      const wasArchived = archivedChannels.includes(meta.channelId);
      if (!!meta.archived !== !!wasArchived) {
        if (meta.archived) {
          archivedChannels = [...archivedChannels, meta.channelId];
        } else {
          archivedChannels = archivedChannels.filter(c => c !== meta.channelId);
        }
        return { ...state, employee: { ...state.employee, archivedChannels } };
      }
      return state;
    }

    case REHYDRATE: {
      if (key === 'auth') {
        const language = payload?.employee?.settings?.language;
        if (language) {
          moment().locale(language);
          moment.locale(language);
          asyncDispatch(setLocale(language));
        }
      }
      return state;
    }

    default:
      return state;
  }
};

const persistConfig = {
  key: 'auth',
  blacklist: ['token', 'isFetching', 'isLoading', 'isError', 'loading'],
  storage: storage('ommnio'),
  throttle: 200,
  version: 0,
  transforms: [AddRehydratedTransform],
};

export default persistReducer(persistConfig, authReducer);
