import moment from 'moment';
import { I18n } from 'utils/i18n';
import isNumeric from 'utils/isNumeric';

export default mydate => {
  if (!mydate) return '';
  const date = isNumeric(mydate) ? moment.unix(mydate) : moment(mydate);
  return date.calendar(null, {
    sameDay: `[${I18n.t('MessagesList.today')}]`,
    lastDay: `[${I18n.t('MessagesList.yesterday')}]`,
    lastWeek: 'dddd',
    sameElse: 'LL',
  });
};
