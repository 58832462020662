import fetchAndDecrypt from 'utils/fetchAndDecrypt';

const decryptAttachment = async (attachment, aesKey) =>
  aesKey
    ? {
        ...attachment,
        httpLink: URL.createObjectURL(await fetchAndDecrypt(attachment, aesKey)),
      }
    : attachment;

export default decryptAttachment;
