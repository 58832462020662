import { APP_DOMAIN, IS_DEV } from 'services/constants';
import Cookie from 'services/Cookie';

export default () => {
  if (IS_DEV) return Cookie.get('company')?.subDomain;
  return (
    window.location.hostname.replace(`.${APP_DOMAIN}`, '').replace(APP_DOMAIN, '') ||
    Cookie.get('company')?.subDomain
  );
};
