import React from 'react';
import { useLocation } from 'react-router';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => Object.fromEntries(new URLSearchParams(search).entries()), [search]);
};

export default useQuery;
