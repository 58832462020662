const EncryptionConfig = {
  derive: {
    name: 'PBKDF2',
    hash: 'SHA-256',
  },
  pre: {
    name: 'AES-GCM',
    exports: 'raw',
    length: 256,
  },
  main: {
    name: 'RSA-OAEP',
    hash: 'SHA-256',
    modulus: 4096,
    exports: {
      public: 'spki',
      private: 'pkcs8',
    },
  },
};

export default EncryptionConfig;
